var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Make your selections to view payments")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Session","filterable":""},on:{"input":function($event){return _vm.fetchPaymentMonitor(true)}},model:{value:(_vm.form.sess_id),callback:function ($$v) {_vm.$set(_vm.form, "sess_id", $$v)},expression:"form.sess_id"}},_vm._l((_vm.sessions),function(session,index){return _c('el-option',{key:index,attrs:{"label":session.name,"value":session.id}})}),1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Term","filterable":""},on:{"input":function($event){return _vm.fetchPaymentMonitor(true)}},model:{value:(_vm.form.term_id),callback:function ($$v) {_vm.$set(_vm.form, "term_id", $$v)},expression:"form.term_id"}},_vm._l((_vm.terms),function(term,index){return _c('el-option',{key:index,attrs:{"label":term.name,"value":term.id}})}),1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Level","filterable":""},on:{"input":function($event){return _vm.fetchPaymentMonitor(true)}},model:{value:(_vm.form.level_id),callback:function ($$v) {_vm.$set(_vm.form, "level_id", $$v)},expression:"form.level_id"}},[_c('el-option',{attrs:{"label":"All","value":"all"}}),_vm._l((_vm.levels),function(level,index){return _c('el-option',{key:index,attrs:{"label":level.level,"value":level.id}})})],2)],1)],1),_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"child_row",fn:function(ref){
var row = ref.row;
return [_c('aside',[_c('school-fee-payments',{attrs:{"currency":_vm.currency,"school-fee-payments":row.school_fee_payments},on:{"approve":function($event){return _vm.fetchPaymentMonitor(false)}}})],1)]}},{key:"student",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_vm._v(_vm._s(row.student.user.first_name + ' ' + row.student.user.last_name))]),_c('br'),_c('span',[_vm._v(_vm._s(row.student.registration_no))])])}},{key:"total_fee",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{attrs:{"type":"info","effect":"dark"}},[_vm._v(" "+_vm._s(_vm.currency + row.total_fee.toLocaleString())+" ")])],1)}},{key:"amount_paid",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{attrs:{"type":"success","effect":"dark"}},[_vm._v(" "+_vm._s(_vm.currency + row.amount_paid.toLocaleString())+" ")])],1)}},{key:"balance",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('el-tag',{attrs:{"type":"danger","effect":"dark"}},[_vm._v(" "+_vm._s(_vm.currency + (parseFloat(row.total_fee - row.amount_paid)).toLocaleString())+" ")])],1)}},{key:"action",fn:function(props){return _c('div',{},[_c('el-tooltip',{attrs:{"content":"View Fee Breakdown","placement":"top"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"gradient-info"},on:{"click":function($event){return _vm.showFeeDetails(props.row)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1)],1)],1)}}]),model:{value:(_vm.payment_monitors),callback:function ($$v) {_vm.payment_monitors=$$v},expression:"payment_monitors"}}),_c('b-modal',{attrs:{"title":"Student Fee Break Down","centered":"","size":"lg","hide-footer":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('student-fee-details',{attrs:{"selected-fee":_vm.selected_fee}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }